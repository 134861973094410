body {
  background-color: #f8f9fa;
}

.text-purple,
.btn-purple {
  color: #6f42c1;
}

.btn-purple {
  background-color: transparent;
  border-color: #6f42c1;
  transition: 0.3s;
}

.btn-purple:hover,
.btn-purple:focus {
  background-color: #6f42c1;
  border-color: #6f42c1;
  color: white;
}

.btn-purple:active {
  background-color: #5a3b9d;
  border-color: #5a3b9d;
  color: white;
}

.btn-purple:focus {
  box-shadow: 0 0 0 0.2rem rgba(111, 66, 193, 0.5);
}

h1 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.form-group {
  margin-bottom: 1.5rem;
}

.mb-4 {
  margin-bottom: 2.5rem !important;
}


.responseContainer {
  white-space: pre-wrap;
  max-width: 100%;
  word-wrap: break-word;
  overflow-wrap: break-word;
}